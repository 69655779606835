export default ({
	course: courseName,
	session: sessionName,
	folder: folderName,
	module: moduleName
}: {
	course: any;
	session: any;
	folder: any;
	module: any;
}) => ({
	course: {
		noContentAvailable: 'No hay contenido disponible',
		view: 'Ver',
		sessionsCompleted: 'Has completado con éxito todas las sesiones del curso %{courseName}',
		congratulations: 'Felicidades',
		great: 'Genial'
	},
	session: {
		nextButton: 'Siguiente',
		previous: 'Anterior',
		quizButton: 'Ir al cuestionario',
		surveyButton: 'Ir a la encuesta',
		end: 'Completar'
	},
	question: {
		next: 'Siguiente',
		previous: 'Anterior',
		saveAndNext: 'Guardar y siguiente',
		answer: 'Responder',
		checkAnswer: 'Verificar respuesta',
		numericalRangeFeedback:
			'La respuesta correcta es un número entre <b>%{lowerNumber}</b> y <b>%{higherNumber}</b> inclusive',
		answerAll: 'Responda todas las preguntas <b>obligatorias</b> antes de continuar.',
		uploadAll: 'Por favor, carga todos los archivos <b>requeridos</b> antes de continuar.',
		maxFiles: 'Solo puedes cargar un máximo de %{number} videos',
		confirmRemove: '¿Estás seguro de que quieres eliminar el archivo',
		confirm: 'Confirmar',
		backToStart: 'Volver a empezar',
		attempt: 'Intente responder la pregunta antes de continuar.',
		question: 'Pregunta',
		of: 'de',
		end: 'Terminar',
		hint: 'Haga clic para ver la sugerencia',
		helperText: {
			essay: 'Introduzca la respuesta a continuación',
			multipleChoice: 'Seleccione una respuesta',
			compoundMultipleChoice: 'Seleccione todas las respuestas adecuadas',
			numericalRange: 'Introduzca un número que esté dentro del rango de respuestas adecuadas',
			shortExact: 'Introduzca la respuesta a continuación',
			sequence: 'Arrastre y coloque los elementos para disponerlos en el orden adecuado',
			ratingScale: 'Arrastre el control deslizante para seleccionar y responder'
		}
	},
	feedback: {
		good: '¡Bien hecho!',
		bad: 'Incorrecto'
	},
	tokenError: {
		'401': 'Su enlace tiene un problema, por favor, contacte a Chalkboard Education para obtener ayuda.',
		'402':
			'Su token está en uso en otro dispositivo. Nuestra institución prohíbe el inicio de sesión en múltiples dispositivos. Contacte a Chalkboard Education si cree que se trata de un error.'
	},
	discussion: {
		title: 'Discusión',
		messagePlaceholder: 'Ingrese su mensaje'
	},
	update: {
		label: 'Descargar',
		checkForUpdates: 'Comprobar actualizaciones',
		needUpdate: 'Hay disponibles actualizaciones de contenido...',
		updating: 'Obteniendo contenido...',
		checking: 'Buscando contenido...',
		fetchingQuizResults: 'Obteniendo resultados del cuestionario...',
		downloadSize: '{{size}} {{type}}',
		downloadProgress: '{{completed}}/{{total}} del contenido y los medios de la sesión descargados',
		toDownload: ' por descargar',
		KB: 'KB',
		MB: 'MB',
		upToDate: '¡Ya se descargó todo el contenido disponible!',
		errorWhileCheckingUpdates: 'Se produjo un problema con la red mientras se buscaban actualizaciones',
		errorWhileUpdating: 'Se produjo un problema con la red mientras se descargaba el contenido',
		stayOnline:
			'Por favor, permanezca conectado y mantenga la página abierta. Esto puede llevar varios minutos.',
		offline: 'Está desconectado.',
		success: '¡El contenido se descargó con éxito!',
		warning: 'Contenido parcialmente descargado',
		retry: 'Vuelva a intentar',
		retryInFewMinutes: 'Por favor, inténtelo de nuevo en unos minutos',
		connectAndRetry: 'Por favor, asegúrate de estar conectado a Internet e intenta de nuevo',
		connectAndRetryInMinutes:
			'Asegúrese de estar conectado a Internet e inténtelo de nuevo en unos minutos',
		downloadInProgress: 'Descarga en progreso',
		contentWillAppear: 'Verás el contenido tan pronto como se complete',
		edit: 'Editar',
		downloadingContent: 'Descargar contenido',
		missing: 'Descargar medios faltantes',
		dismiss: 'Descartar',
		contentLoading:
			'Descarga de contenido en progreso. Puede ver el estado tocando el círculo en la esquina inferior derecha de su pantalla'
	},
	send: {
		label: {
			quiz: 'Enviar su progreso con:',
			survey:
				"¡Excelente trabajo al completar la encuesta! Para garantizar la precisión, por favor revise sus respuestas. Una vez que esté seguro de sus respuestas, proceda haciendo clic en el botón 'Enviar'"
		},
		sessionListButton: `lista de ${sessionName}`,
		nextButton: 'Siguiente',
		medium: {
			internet: 'Internet',
			sms: 'SMS'
		},
		validation: {
			success: 'Gracias. Se guardó su progreso.',
			fail: `Error mientras se validaba el ${sessionName}`
		},
		sms: {
			title: 'Envíe su progreso por SMS',
			label:
				'Debe enviar un código por SMS y recibirá un código de progreso como respuesta. No modifique ninguno de los códigos. Gracias.',
			button: 'Enviar por SMS',
			notworking: {
				label: 'Enviar SMS manualmente...',
				button: '¿No funciona?',
				toPhone: 'Número de teléfono',
				unblockCode: 'Código a enviar'
			},
			progressCode: {
				button: 'Introduzca el código de progreso',
				placeholder: 'Código de progreso',
				title: 'Introduzca el código que recibió como respuesta del SMS a continuación:',
				cancel: 'Cancelar',
				submit: 'Enviar'
			}
		}
	},
	login: {
		checking: 'Comprobando...',
		start: 'Comenzar',
		title: 'Iniciar sesión',
		message:
			'Puede iniciar sesión con el enlace provisto por SMS. En su defecto, puede introducir un token de inicio de sesión a continuación.',
		hint:
			'Puede usar el enlace de inicio de sesión que se le envió por SMS para volver a iniciar sesión.<br />O bien puede encontrar su token de 6 caracteres añadido a la URL de inicio de sesión que se le envió por SMS. Por ejemplo: https://chalkedu.co/#/login/<b>token</b>',
		tokenPlaceholder: 'Token'
	},
	account: {
		progress: {
			title: `Progreso`,
			subtitle: `%{read}/%{remaining} Lectura de sesiones`
		},
		language: 'Idioma',
		settings: {
			label: 'Configuración'
		},
		about: 'Acerca de',
		consent: 'Consentimiento',
		support: {
			title: 'Líneas de asistencia',
			subtitle: 'Puede contactarnos de estas maneras para obtener más ayuda:'
		}
	},
	error: {
		label: 'Lo sentimos. Parece que algo salió mal.',
		report: 'Informar error',
		back: 'Volver a Inicio',
		dismiss: 'Descartar',
		authenticationError: 'Error de autenticación',
		spoolError: {
			title: 'Descarga incompleta',
			description:
				"Some files failed to download. Not to worry, we'll attempt to redownload them on the next update."
		}
	},
	notFound: {
		title: '404 página no encontrada',
		description: 'Lo sentimos, pero la página que está buscando no existe.',
		back: 'Volver'
	},
	logout: {
		title: 'Confirmar el cierre de sesión',
		message: '¿Está seguro de que desea cerrar la sesión?',
		button: 'Cerrar sesión',
		cancelButton: 'Cancelar'
	},
	selfTestQuiz: {
		okay: 'Aceptar',
		checkAnswer: 'Verificar respuesta',
		answers: 'Respuestas',
		essayResponse: '¡Gracias por su respuesta!'
	},
	quiz: {
		label: '¡Debe completar el siguiente cuestionario antes de continuar!',
		button: {
			begin: 'Comenzar',
			skip: 'Omitir'
		},
		upload: 'Seleccionar archivos para cargar',
		uploadedFiles: 'Archivos cargados',
		tapToUpload: 'Toque el icono para seleccionar archivos para cargar',
		commence: 'Estás a punto de hacer el cuestionario de esta sesión',
		submit: {
			submitting: 'Enviando',
			stayOnline:
				'¿Estás seguro de que quieres enviar tus respuestas?\nAsegúrate de estar en línea y mantén la pantalla abierta.',
			inProgress: 'Sumisión en progreso. Esto puede tomar hasta un minuto',
			success: '¡El cuestionario se envió con éxito!',
			error: '¡Error al enviar el cuestionario! Por favor, intente nuevamente más tarde.',
			offline: 'No se pueden enviar respuestas sin conexión'
		},
		continue: 'Continuar',
		submitButton: 'Enviar cuestionario'
	},
	survey: {
		submitButton: 'Enviar encuesta',
		submittedButton: 'Encuesta enviada',
		commence: 'Estás a punto de responder a la encuesta de esta sesión',
		submit: {
			success: 'Encuesta enviada con éxito!'
		}
	},
	badge: {
		quiz: 'Cuestionario',
		survey: 'Encuesta',
		required: 'Obligatorio'
	},
	menu: {
		modules: `${moduleName}`,
		courses: `${courseName}`,
		account: 'Cuenta',
		bookmarks: 'Marcadores',
		quizReview: 'Revisión del cuestionario',
		help: 'Ayuda',
		about: 'Acerca de'
	},
	consent: {
		title: 'Doy mi consentimiento para que Chalkboard Education SAS recopile y conserve:',
		data: 'Mis datos de progreso y resultado de esta aplicación',
		analytics:
			'Mis datos de comportamiento y otra información personal relacionada con el uso de esta aplicación'
	},
	custom: {
		moreInfo: 'Más información',
		gotIt: 'Entendido',
		searchPlaceholder: 'Empiece a escribir para buscar',
		noSearchResults: 'No se han encontrado resultados'
	},
	help: {
		title: 'Cómo navegar en la aplicación:',
		menu: {
			title: 'Descubrir el menú',
			subtitle: `Haga clic en el icono para abrir el menú. El menú contiene un enlace a sus ${courseName} y su cuenta.`
		},
		courses: {
			title: `${courseName}`,
			subtitle: `On the ${courseName}s page, click on 'Ver' to open a ${courseName}.`
		},
		folders: {
			title: `${folderName}`,
			subtitle: `Cada ${courseName} está dividido en ${folderName}.`,
			description: `Cada ${folderName} está dividido además en ${sessionName}. Los ${sessionName} tienen el contenido que el usuario puede estudiar.`
		},
		sessions: {
			title: `${sessionName}`,
			subtitle: `los ${sessionName} tienen contenido que el usuario puede estudiar.`
		},
		navigatingSessions: {
			title: `Navegación por los ${sessionName}`,
			back: {
				subtitle: `Una vez que se encuentra en un ${sessionName}, puede volver a la lista principal con la flecha hacia atrás...`
			},
			next: {
				subtitle: `O ir al siguiente ${sessionName} con el botón que se encuentra en la parte inferior de la página:`
			}
		},
		bookmarks: {
			title: 'Marcadores',
			subtitle:
				'Use el icono de marcador para guardar las páginas para un acceso rápido desde la sección Marcadores dentro del menú lateral'
		},
		quizzes: {
			title: 'Cuestionarios/pruebas',
			subtitle: `Se le puede presentar un cuestionario al inicio de un ${courseName} o ${folderName} O al final de un ${sessionName}.`,
			description: `Atención: los ${sessionName} con cuestionario están marcados con el icono:`
		},
		addToHomescreen: {
			title: 'Agregar a la pantalla de inicio',
			subtitle:
				"You can install this application on your homescreen for quick and easy access when you're on the go."
		},
		account: {
			title: 'Cuenta',
			subtitle: 'En la página de cuenta, puede:',
			updates: {
				description: 'Buscar actualizaciones del contenido.'
			},
			language: {
				description: 'Cambiar sus configuraciones de idioma.'
			},
			logout: {
				description: 'Cerrar sesión de la cuenta.'
			},
			contact: {
				description: 'Contáctenos si tiene problemas para usar la aplicación.'
			}
		},
		tutorial: 'Volver a ejecutar el tutorial interactivo:'
	},
	onboarding: {
		'0': {
			title: 'Lecciones a demanda',
			subtitle:
				'Todo el material de aprendizaje en un lugar... Incluyendo cuestionarios para probar su conocimiento.'
		},
		'1': {
			title: '¡Accesible sin conexión!',
			subtitle:
				'Una vez que descargue su contenido,  estará disponible sin conexión para poder acceder a él en cualquier momento.'
		},
		'2': {
			title: '¡Aprenda en cualquier momento y lugar!',
			subtitle:
				'Acceda a su contenido desde cualquier dispositivo. Simplemente use el enlace provisto por SMS.'
		},
		'3': {
			title: '¡Únase al debate!',
			subtitle: `Participe en el aprendizaje entre pares en ${sessionName} que permiten el debate.`
		},
		'4': {
			title: '¡Agregar a su pantalla de inicio!',
			subtitle:
				"Install this application on your homescreen for quick and easy access when you're on the go.",
			instructions: {
				menu:
					'Simplemente pulse el botón <strong>menú</strong> en su navegador <br />y seleccione <strong>Agregar a la pantalla de inicio</strong>',
				share:
					'Simplemente pulse el botón <strong>compartir</strong> en su navegador <br />y seleccione <strong>Agregar a la pantalla de inicio</strong>',
				clickButton: '...o haga clic en el botón a continuación'
			}
		},
		addToHomescreen: 'Agregar a la pantalla de inicio',
		getStarted: 'Comenzar',
		next: 'Siguiente',
		skip: 'Omitir'
	},
	'guided-tour': {
		title: 'Tutorial interactivo',
		button: {
			finish: 'Fin',
			next: 'Siguiente',
			previous: 'Anterior',
			're-run': 'Volver a ejecutar'
		},
		modules: {
			'1': {
				title: `Página de ${moduleName}`,
				content: `Bienvenido a la página de <b>${moduleName}</b>. Los <b>${moduleName}</b> son grupos de <b>${courseName}</b> que se le han asignado`
			},
			'2': {
				content: `Si hace clic en un <b>${moduleName}</b>, pasa a una lista de todos los <b>${courseName}</b> incluidos en el <b>${moduleName}</b>`
			}
		},
		'module-courses': {
			'1': {
				content: `Usted está en la página de <b>${courseName}</b> del <b>${moduleName}</b> que acaba de seleccionar. Los ${courseName} incluidos en el <b>${moduleName}</b> se enumeran aquí`
			}
		},
		courses: {
			'1': {
				title: `Página de ${courseName}`,
				content: `Usted está en la página <b>${courseName}</b>. Todos sus <b>${courseName}</b> están enumerados aquí`
			},
			'2': {
				content:
					'Siempre que vea esto, significa que hay un contenido nuevo disponible para la descarga'
			},
			'3': {
				content: 'Si hace clic en <b>Descargar</b>, guarda el contenido nuevo en su dispositivo'
			},
			'4': {
				content: `Si hace clic en la imagen, se muestra una descripción breve del <b>${courseName}</b>`
			},
			'5': {
				content: `Si hace clic en un <b>${courseName}</b>, irá a la página de información de ese <b>${courseName}</b>`
			},
			'6': {
				content: 'Haga clic en <b>Buscar actualizaciones</b> para comprobar si hay contenido nuevo'
			}
		},
		'course-info': {
			'1': {
				content: `Esta es la <b>Página de ${courseName}</b>. Aquí encontrará más información sobre un ${courseName}, además de los <b>${sessionName}</b> agrupados en <b>${folderName}</b>`
			},
			'2': {
				content: `Puede encontrar una descripción breve sobre el <b>nombre del curso</b> aquí`
			},
			'3': {
				content: `Debajo de la descripción del ${courseName} aparece una lista de <b>${folderName}</b>`
			},
			'4': {
				content: `Si hace clic en un <b>${folderName}</b>, se muestra la lista de <b>${sessionName}</b> incluidos en ese ${folderName}`
			},
			'5': {
				content: `Aquí está la lista de <b>${sessionName}</b>`
			},
			'6': {
				content: `Para comenzar a leer, haga clic en <b>${sessionName}</b>`
			}
		},
		'session-details': {
			'1': {
				content: `Bienvenido a su primer <b>${sessionName}</b>. Aquí es donde pasará la mayor parte del tiempo`
			},
			'2': {
				content: `Para que sea más fácil volver a un <b>${sessionName}</b>, haga clic en el icono de <b>Marcador</b> para crear un enlace rápido de regreso a este <b>${sessionName}</b>. Los <b>marcadores</b> que cree pueden encontrarse en la <b>Página de marcadores</b>`
			},
			'3': {
				content: 'Si hace clic en la flecha hacia arriba puede ir a la página anterior.'
			},
			'4': {
				content: `Haga clic en el <b>icono de Debate</b> para ver la <b>Página de debate</b> del <b>${sessionName}</b> que está mirando en ese momento`
			},
			'5': {
				content: `Para ir al siguiente <b>${sessionName}</b> del <b>${folderName}</b>, haga clic en el <b>botón Siguiente</b>`
			},
			'6': {
				content: `Para ir al <b>${sessionName}</b> previo, haga clic en el <b>botón Anterior</b>`
			},
			'7': {
				content:
					'Este es el <b>botón de Menú</b>. Si hace clic en este botón desde cualquier página, aparece el <b>menú lateral</b>'
			}
		},
		bookmarks: {
			'1': {
				content: '¡Todas las páginas que ha marcado aparecen aquí!'
			},
			'2': {
				content: 'Si hace clic en <b>Marcador</b>, pasa rápidamente a la página con marcador'
			}
		},

		help: {
			'1': {
				content:
					'Bienvenido a la <b>Página de ayuda</b>. Visite esta página siempre que tenga problemas para comprender el funcionamiento de la aplicación'
			},
			'2': {
				content:
					'Puede visitar la <b>Página de ayuda</b> para volver a ejecutar esta guía en cualquier momento...'
			},
			'3': {
				content:
					'Haga clic en cualquiera de los elementos de la lista para obtener más información...'
			},
			'4': {
				content: 'También puede contactar a Chalkboard Education por correo electrónico'
			},
			'5': {
				content: 'O hable con nosotros llamándonos a nuestra línea gratuita...'
			}
		},

		quiz: {
			'1':
				'Bienvenido a la <b>Página de cuestionario</b>. Verá una pantalla similar siempre que esté por intentar contestar un cuestionario',
			'2':
				'Aquí están los detalles del cuestionario. Haga clic en el botón <b>Comenzar</b> para comenzar...'
		},
		quizQuestion: {
			'1': 'Esto muestra en qué pregunta se encuentra y cuántas preguntas le faltan...',
			'2':
				'Interactúe y responda la pregunta. Haga clic en el botón <b>Siguiente</b> en la parte inferior de la pantalla para continuar con la pregunta siguiente',
			'3':
				'Sus respuestas se guardan automáticamente en su dispositivo una vez que las ingresa. ¡Puede volver a ellas después, es posible hacerlo hasta que envíe el cuestionario!'
		}
	},
	routes: {
		login: 'Iniciar sesión',
		welcome: 'Bienvenido',
		modules: `${moduleName}`,
		courses: `${courseName}`,
		help: 'Ayuda',
		about: 'Acerca de',
		bookmarks: 'Marcadores',
		quizReview: 'Revisión del cuestionario',
		account: 'Cuenta',
		folders: `${folderName}`,
		sessions: `${sessionName}`,
		quiz: 'Cuestionario',
		question: 'Pregunta'
	},
	bookmarks: {
		none: 'Parece que no tiene marcadores guardados...',
		added: 'Marcador agregado',
		removed: 'Marcador eliminado'
	},
	courses: {
		none: `Los ${courseName} descargados aparecerán aquí...`,
		noPurchases: `Aún no ha hecho ninguna compra`,
		purchaseMore: `Haga clic aquí para agregar los ${courseName}`,
		purchaseCourses: `Compre ${courseName}`
	},
	quizReview: {
		questionsTitle: {
			survey: 'Preguntas y respuestas',
			graded: 'Desglose'
		},
		none: `No ha completado ningún cuestionario <b>con calificación</b> ni <b>encuestas</b>`,
		correct: 'Correcto',
		wrong: 'Incorrecto',
		notMarked: 'Aún no marcado',
		resultsIsFetching: 'Recuperando los cuestionarios anteriores para revisión...',
		errorFetchingResults:
			'Lo siento, hubo un error al obtener los resultados del cuestionario. Por favor, revise las actualizaciones e inténtelo de nuevo.'
	},
	folder: {
		quizLabel: `Debe completar un cuestionario antes de ver los contenidos de este ${folderName}:`,
		surveyLabel: `Debe completar una encuesta antes de ver los contenidos de este ${folderName}:`
	},
	modules: {
		none: `No se descargaron nombres del módulo...`
	},
	register: {
		signUp: 'Suscribirse',
		button: 'REGISTRARSE',
		registering: 'REGISTRANDO...',
		loginHint: '¿Ya tiene una cuenta? Iniciar sesión',
		successful: 'Registro exitoso',
		failed: 'Falló el registro. Vuelva a intentarlo.',
		label: {
			firstName: 'Nombre',
			lastName: 'Apellido',
			phoneNumber: 'Número de teléfono',
			language: 'Idioma'
		},
		hint: {
			firstName: 'El primer nombre es obligatorio',
			lastName: 'El apellido es obligatorio',
			phoneNumberLength: 'El número de teléfono debe tener más de 9 dígitos',
			phoneNumberFormat: "Phone number should start with a '+'",
			verifyHuman: 'Verifique el recaptcha para continuar',
			language: 'El idioma es obligatorio'
		}
	},
	searchModal: {
		cancel: 'Cancelar',
		search: 'Buscar',
		searchIn: 'Buscar En'
	},
	quizType: {
		survey: 'Encuesta',
		graded: 'Calificada',
		selfAssessment: 'Autoevaluación'
	}
});
