export default ({
	course: courseName,
	session: sessionName,
	folder: folderName,
	module: moduleName
}: {
	course: any;
	session: any;
	folder: any;
	module: any;
}) => ({
	course: {
		noContentAvailable: 'Hakuna taarifa iliyomo',
		view: 'Angalia',
		sessionsCompleted: 'Umefanikiwa kukamilisha vipindi vyote katika kozi ya %{courseName}',
		congratulations: 'Hongera',
		great: 'Nzuri sana'
	},
	session: {
		nextButton: 'Inayofuata',
		previous: 'Iliyopita',
		quizButton: 'Endelea kwenye Zoezi',
		surveyButton: 'Endelea kwenye Mapitio',
		end: 'Kamilisha'
	},
	question: {
		next: 'Inayofuata',
		previous: 'Iliyopita',
		saveAndNext: 'Hifadhi & Inayofuata',
		answer: 'Jibu',
		checkAnswer: 'Chunguza Jibu',
		numericalRangeFeedback:
			'Jibu sahihi ni namba kati ya <b>%{lowerNumber}</b> na % {higherNumber} inayojumuisha </b>',
		answerAll: 'Tafadhali jibu maswali yote yanayotakiwa ya  <b>  na </b> kabla ya kuendelea',
		uploadAll: 'Tafadhali pakia faili zote <b>zilizohitajika</b> kabla ya kuendelea.',
		maxFiles: 'Unaweza kupakia hadi %{number} video pekee',
		confirmRemove: 'Una uhakika unataka kuondoa faili',
		confirm: 'Thibitisha',
		backToStart: 'Rudi Mwanzoni',
		attempt: 'Tafadhali jibu swali kabla hujaendelea',
		question: 'Swali',
		of: 'la',
		end: 'Maliza',
		hint: 'Tafadhali bofya kitufe cha kidokezo',
		helperText: {
			essay: 'Ingiza jibu chini',
			multipleChoice: 'Chagua jibu moja',
			compoundMultipleChoice: 'Chagua majibu yote yanayofaa',
			numericalRange: 'Weka namba kwenye majibu mbalimbali yanayofaa',
			shortExact: 'Ingiza jibu chini',
			sequence: 'Buruta na weka vitu ili uvipange  katika mpangilio unaofaa',
			ratingScale: 'Buruta slaidi ya kuchagua na kujibu'
		}
	},
	feedback: {
		good: 'Kazi Nzuri!',
		bad: 'Siyo sahihi'
	},
	tokenError: {
		'401': 'Kiunganishio chako kina tatizo - tafadhali wasiliana na Chalkboard Education kwa msaada',
		'402':
			'Tokeni yako inatumiwa katika kifaa kingine. Uingiaji kwenye vifaa vingi umezuiwa na taasisi yako. Tafadhali wasiliana na Chalkboard Education iwapo unaamini kuwa hili ni kosa.'
	},
	discussion: {
		title: 'Majadiliano',
		messagePlaceholder: 'Ingiza ujumbe wako'
	},
	update: {
		label: 'Pakua',
		checkForUpdates: 'Angalia taarifa mpya',
		needUpdate: 'Taarifa mpya zipo',
		updating: 'Utoaji wa taarifa…',
		checking: 'Kuangalia taarifa….',
		fetchingQuizResults: 'Inaleta matokeo ya maswali...',
		downloadSize: '{{size}} {{type}}',
		downloadProgress: '{{completed}}/{{total}} ya maudhui na vyombo vya habari vya kikao vimepakuliwa',
		toDownload: ' kupakua',
		KB: 'KB (kwa Kilobaiti)',
		MB: 'MB (kwa Megabaiti)',
		upToDate: 'Taarifa zote zilizomo zimeshapakuliwa!',
		errorWhileCheckingUpdates: 'Kulikuwa na tatizo la mtandao wakati wa kuangalia taarifa mpya',
		errorWhileUpdating: 'Tatizo la mtandao lilitokea wakati wa kupakua taarifa.',
		stayOnline: 'Tafadhali baki mtandaoni na uache ukurasa wazi. Hii inaweza kuchukua dakika kadhaa.',
		offline: 'Hauko mtandaoni.',
		success: 'Taarifa zimepakuliwa ipasavyo!',
		warning: 'Taarifa zimepakuliwa kwa sehemu',
		retry: 'Jaribu tena',
		retryInFewMinutes: 'Tafadhali jaribu tena baadaye kidogo',
		connectAndRetry: 'Tafadhali hakikisha umeunganishwa kwenye mtandao kisha jaribu tena',
		connectAndRetryInMinutes:
			'Tafadhali hakikisha umeunganishwa kwenye mtandao na jaribu tena baadaye kidogo',
		downloadInProgress: 'Upakuaji unaendelea',
		contentWillAppear: 'Utaona maudhui mara tu yatakapokamilika',
		edit: 'Hariri',
		downloadingContent: 'Taarifa zinapakuliwa',
		missing: 'Pakua media iliyokosekana',
		dismiss: 'Ondoa',
		contentLoading:
			'Upakuaji wa maudhui unaendelea. Unaweza kuona hali kwa kugusa duara chini kulia mwa skrini yako'
	},
	send: {
		label: {
			quiz: 'Wasilisha maendeleo yako pamoja na:',
			survey:
				"Kazi nzuri kwa kumaliza uchunguzi! Ili kuhakikisha usahihi, tafadhali pitia majibu yako. Mara tu utakapokuwa na uhakika na majibu yako, tafadhali endelea kwa kubonyeza kitufe 'Wasilisha'"
		},
		sessionListButton: `orodha ya majina ya vipindi`,
		nextButton: 'Inayofuata',
		medium: {
			internet: 'Intaneti',
			sms: 'Arafa'
		},
		validation: {
			success: 'Asante. Maendeleo yako yamehifadhiwa.',
			fail: `Makosa wakati wa kuthibitisha ${sessionName}`
		},
		sms: {
			title: 'Wasilisha maendeleo yako kwa arafa',
			label:
				'Utatuma msimbo kwa arafa na kupokea msimbo wa maendeleo. Usirekebishe misimbo yoyote. Asante.',
			button: 'Tuma kwa arafa',
			notworking: {
				label: 'Tuma arafa kwa mkono…',
				button: 'Haifanyi kazi ?',
				toPhone: 'Namba ya Simu',
				unblockCode: 'Weka msimbo ili utume'
			},
			progressCode: {
				button: 'Ingiza Msimbo wa Maendeleo',
				placeholder: 'Msimbo wa maendeleo',
				title: 'Ingiza msimbo ulioupata kwa arafa hapo chini:',
				cancel: 'Futa',
				submit: 'Wasilisha'
			}
		}
	},
	login: {
		checking: 'Kukagua…',
		start: 'Anza',
		title: 'Ingia',
		message:
			'Unaweza kuingia kwa kiunganishio cha arafa kilichotolewa. Njia nyingine, unaweza kuingiza tokeni yako ya kuingilia hapo chini.',
		hint:
			'tumia arafa kurudi tena kuingia.<br /> Njia nyingine, tokeni yako ya herufi 6 inaweza kuonekana imeambatishwa kwenye URL ya kuingilia iliyotumwa kwako kwa arafa. Kwa mfano: https://chalkedu.co/#/login/<b>tokeni</b>',
		tokenPlaceholder: 'Tokeni'
	},
	account: {
		progress: {
			title: `Maendeleo`,
			subtitle: `%{read}/%{remaining} Vipindi vilivyosomwa`
		},
		language: 'Lugha',
		settings: {
			label: 'Mipangilio'
		},
		about: 'Kuhusu',
		consent: 'Idhini',
		support: {
			title: 'Mistari ya Msaada',
			subtitle: 'Unaweza kuwasiliana nasi kwa njia hizi kwa ajili ya msaada zaidi:'
		}
	},
	error: {
		label: 'Oh…, Inaonekana kama kuna kitu hakikwenda sawa.',
		report: 'Ripoti Kosa',
		back: 'Rudi kwenye Ukurasa wa Nyumbani',
		dismiss: 'Ondoa',
		authenticationError: 'Kosa lenye Uthibitisho',
		spoolError: {
			title: 'Upakuaji Ambao Haukukamilika',
			description:
				"Some files failed to download. Not to worry, we'll attempt to redownload them on the next update."
		}
	},
	notFound: {
		title: 'Ukurasa 404 hauonekani',
		description: 'Samahani lakini ukurasa unaoutafuta haupo',
		back: 'Rudi Nyuma'
	},
	logout: {
		title: 'Thibitisha Kutoka Mtandaoni.',
		message: 'Je, una uhakika kuwa unataka kutoka mtandaoni?',
		button: 'Toka Mtandaoni',
		cancelButton: 'Futa'
	},
	selfTestQuiz: {
		okay: 'Sawa',
		checkAnswer: 'Chunguza Jibu',
		answers: 'Majibu',
		essayResponse: 'Tunakushukuru kwa majibu yako!'
	},
	quiz: {
		label: 'Lazima ukamilishe Zoezi lifuatalo kabla hujaendelea!',
		button: {
			begin: 'Anza',
			skip: 'Ruka'
		},
		commence: 'Unakaribia kuchukua jaribio la kikao hiki',
		upload: 'Chagua faili kwa ajili ya kupakia',
		uploadedFiles: 'Faili zilizopakiwa',
		tapToUpload: 'Bonyeza ikoni kuchagua faili za kupakia',
		submit: {
			submitting: 'Inawasilisha',
			stayOnline:
				'Una uhakika unataka kuwasilisha majibu yako?\nTafadhali hakikisha uko mtandaoni na uweke skrini wazi.',
			inProgress: 'Uwasilishaji unaendelea. Hii inaweza kuchukua hadi dakika moja',
			success: 'Jaribio limewasilishwa kwa ipasavyo!',
			error: 'Kosa wakati wa kuwasilisha zoezi! Tafadhali jaribu tena baadaye.',
			offline: 'Haiwezekani kuwasilisha majibu bila mtandao'
		},
		continue: 'Endelea',
		submitButton: 'Wasilisha jibu'
	},
	survey: {
		submitButton: 'Wasilisha jibu',
		submittedButton: 'Mapitio Yaliyowasilishwa',
		commence: 'Unakaribia kujibu uchunguzi wa kikao hiki',
		submit: {
			success: 'Uchunguzi umejibiwa kwa mafanikio!'
		}
	},
	badge: {
		quiz: 'Zoezi',
		survey: 'Mapitio',
		required: 'Kinachotakiwa'
	},
	menu: {
		modules: `${moduleName}`,
		courses: `${courseName}`,
		account: 'Akaunti',
		bookmarks: 'Alama',
		quizReview: 'Mapitio ya Zoezi',
		help: 'Msaada',
		about: 'Kuhusu'
	},
	consent: {
		title: 'Ninauidhinisha ukusanyaji na uhifadhi wa SAS Chalkboard Education',
		data: 'Data zangu za maendeleo na matokeo kutoka kwenye programu tumizi hii',
		analytics:
			'Data zangu za kimwenendo na  taarifa nyingine binafsi kuhusiana na matumizi ya programu tumizi hii'
	},
	custom: {
		moreInfo: 'Taarifa zaidi',
		gotIt: 'Nimeipata',
		searchPlaceholder: 'Anza kuandika ili utafute',
		noSearchResults: 'Hakuna matokeo yaliyopatikana'
	},
	help: {
		title: 'Jinsi ya kusakura programu tumizi',
		menu: {
			title: 'Gundua menyu',
			subtitle: `Bofya ikoni ili kufungua menyu. Menyu ina kiunganishio kwenye ${courseName} yako na Akaunti yako.`
		},
		courses: {
			title: `${courseName}`,
			subtitle: `On the ${courseName}s page, click on 'Angalia' to open a ${courseName}.`
		},
		folders: {
			title: `Chini ya maelezo ya ${courseName} ni orodha ya  <b> ${folderName}  </b>`,
			subtitle: `Kila ${courseName} limegawanyika kwenye majina ya ma${folderName}.`,
			description: `Kila ${folderName} limegawanyika zaidi katika majina ya vipindi, majina yana taarifa ambazo mtumiaji anaweza kuzisoma.`
		},
		sessions: {
			title: `${sessionName}`,
			subtitle: `${sessionName} yana taarifa ambazo mtumiaji anaweza kuzisoma.`
		},
		navigatingSessions: {
			title: `Kusakura ${sessionName}`,
			back: {
				subtitle: `Mara unapokuwa kwenye ${sessionName}, unaweza ama kusakura kurudi kwenye orodha mama kwa mshale wa kurudi nyuma…`
			},
			next: {
				subtitle: `Au kwenye jina linalofuata kwa kitufe chini ya ukurasa:`
			}
		},
		bookmarks: {
			title: 'Alama',
			subtitle:
				'Tumia ikoni ya alama kuhifadha kurasa kwa ajili ya upataji wa haraka kutoka kwenye sehemu ya alama katika menyu ya slaidi'
		},
		quizzes: {
			title: 'Mazoezi/Majaribio',
			subtitle: `Unaweza kupatiwa Zoezi mwanzoni mwa ${courseName}, au ${folderName}. Au mwishoni mwa ${sessionName}.`,
			description: `Tanbihi: majina ya vipindi yenye Zoezi yamewekewa alama ya ikoni:`
		},
		addToHomescreen: {
			title: 'Ongeza kwenye Skrini ya nyumbani',
			subtitle:
				"You can install this application on your homescreen for quick and easy access when you're on the go."
		},
		account: {
			title: 'Akaunti',
			subtitle: 'Katika ukurasa wa Akaunti, unaweza:',
			updates: {
				description: 'Angalia taarifa mpya'
			},
			language: {
				description: 'Badilisha mipangilio yako ya lugha'
			},
			logout: {
				description: 'Kutoka kwenye akaunti yako'
			},
			contact: {
				description: 'Kuwasiliana nasi kwa masuala yoyote uliyonayo katika kutumia programu tumizi.'
			}
		},
		tutorial: 'Kuendesha tena ufundishaji wa mazungumzo'
	},
	onboarding: {
		'0': {
			title: 'Masomo kutokana na mahitaji',
			subtitle:
				'Vifaa vyako vyote vya ujifunzaji mahali pamoja…ikiwa ni pamoja na mazoezi kupima maarifa yako.'
		},
		'1': {
			title: 'Kuondoka mtandaoni…kumeimarishwa!',
			subtitle:
				'Mara tu taarifa zako zinapopakuliwa, zinakuwepo bila kuingia mtandaoni na hivyo unaweza kuziona wakati wowote.'
		},
		'2': {
			title: 'Jifunze wakati wowote, mahali popote!',
			subtitle: 'Pata taarifa zako kwenye kifaa chochote. Tumia kiunganishi cha arafa kilichotolewa.'
		},
		'3': {
			title: 'Jiunge kwenye majadiliano!',
			subtitle: `Shiriki katika ujifunzaji wa wawiliwawili kuhusu majadiliano-majina ya vipindi yaliyowezeshwa`
		},
		'4': {
			title: 'Ongeza kwenye skrini yako ya nyumbani!',
			subtitle:
				"Install this application on your homescreen for quick and easy access when you're on the go.",
			instructions: {
				menu:
					'Sogeza kitufe cha <strong>menyu</strong> kwenye kisakura chako <br> /> na chagua <strong> Ongeza kwenye Skrini ya Nyumbani</strong>',
				share:
					'Sogeza kitufe cha <strong>changia</strong> kwenye kisakura chako <br> /> na chagua <strong> Ongeza kwenye Skrini ya Nyumbani</strong>',
				clickButton: '…au kwa kubofya kitufe hapo chini'
			}
		},
		addToHomescreen: 'Ongeza kwenye Skrini ya nyumbani',
		getStarted: 'Anza',
		next: 'Inayofuata',
		skip: 'Ruka'
	},
	'guided-tour': {
		title: 'Ufundishaji Mtangamano',
		button: {
			finish: 'Mwisho',
			next: 'Inayofuata',
			previous: 'Iliyopita',
			're-run': 'Endesha tena'
		},
		modules: {
			'1': {
				title: `Ukurasa wa ${moduleName}`,
				content: `Karibu <b> ukurasa wa ${moduleName} </b>.  <b> ${moduleName} </b> ni makundi ya  <b>  ${courseName} </b> ambazo umepangiwa`
			},
			'2': {
				content: `Kubofya kwenye <b> ${moduleName} </b> kutakupeleka kwenye orodha ya <b> majina yote ya ${courseName}  </b>  chini ya  <b> ${moduleName}  </b>`
			}
		},
		'module-courses': {
			'1': {
				content: `Uko kwenye  <b> ${courseName}  </b> ukurasa wa  <b> jina la ${moduleName}  </b> umeshachaguliwa. ${courseName} chini ya  <b> jina la ${moduleName}  <b> vimeorodheshwa hapa`
			}
		},
		courses: {
			'1': {
				title: `Ukurasa wa ${courseName}`,
				content: `Uko kwenye  <b> ukurasa wa ${courseName}  </b>. Majina yote  <b> ya ${courseName} zako  </b>  yameorodheshwa hapa`
			},
			'2': {
				content: 'Pale unapoliona hili, maana yake ni kuna taarifa mpya kwa ajili ya kupakuliwa'
			},
			'3': {
				content: 'Kubofya kwenye  <b> Pakua  </b> kunahifadhi taarifa mpya kwenye kifaa chako'
			},
			'4': {
				content: `Kubofya kwenye picha kunaashiria maelezo mafupi ya  <b> ${courseName}  </b>`
			},
			'5': {
				content: `Kubofya kwenye  <b> ${courseName}  </b>  kutakupeleka kwenye ukurasa wa taarifa za  <b> jina hilo la ${courseName}  </b>`
			},
			'6': {
				content: 'Bofya kwenye  <b> Angalia Taarifa Mpya  </b> kuangalia taarifa mpya'
			}
		},
		'course-info': {
			'1': {
				content: `Kubofya kwenye picha kunaashiria maelezo mafupi ya  <b> ${courseName}  </b>`
			},
			'2': {
				content: `Unaweza kupata maelezo mafupi kuhusu  <b> ${courseName}  <b>  hapa`
			},
			'3': {
				content: `Chini ya maelezo ya ${courseName} ni orodha ya  <b> ${folderName}  </b>`
			},
			'4': {
				content: `Kubofya kwenye  <b> ${folderName}  </b> kunaonyesha orodha ya  <b> majina ya vipindi  </b>  chini ya jina hilo la ${folderName}`
			},
			'5': {
				content: `Hapa kuna orodha ya  <b> majina ya vipindi  </b>`
			},
			'6': {
				content: `Kuanza kusoma, bofya kwenye  <b> ${sessionName}  </b>`
			}
		},
		'session-details': {
			'1': {
				content: `Karibu kwenye  <b> ${sessionName} chako cha kwanza kabisa  </b> . Hapa ndipo utakapokuwa unatumia muda wako mwingi`
			},
			'2': {
				content: `Ili kurahisisha kurudi kwenye  <b> ${sessionName}  </b>, bofya  </b> ikoni ya Alama  </b> ili kuunda kiunganishio cha haraka kurudi huku <b> ${sessionName}  </b> .  <b>  Alama  </b> ulivyounda vinaweza kuonekana kwenye  <b> Ukurasa wa Alama  </b>`
			},
			'3': {
				content: 'Kubofya mshale wa kuelekea juu kunakupeleka kwenye ukurasa uliopita'
			},
			'4': {
				content: `Bofya  <b> Ikoni ya Majadiliano  </b>  kuona  <b> Ukurasa wa Majadiliano  </b> wa  <b> ${sessionName}  </b> unachokiangalia kwa sasa`
			},
			'5': {
				content: `Kila ${folderName} limegawanyika zaidi katika majina ya vipindi, majina yana taarifa ambazo mtumiaji anaweza kuzisoma.`
			},
			'6': {
				content: `Ili kwenda kwenye  <b> ${sessionName} kilichopita  </b>, bofya <b>  Kitufe Kilichopita  </b>`
			},
			'7': {
				content:
					'Hiki ni  <b> Kitufe cha Menyu  </b>. kibofye  kwenye ukurasa wowote, kitaonyesha  <b> Slaidi ya Menyu  </b>'
			}
		},
		bookmarks: {
			'1': {
				content: 'Kurasa zote ulizoziwekea alama zitaonekana hapa'
			},
			'2': {
				content:
					'Kubofya kwenye  <b> Alama  </b>  kutakupeleka haraka kwenye ukurasa uliowekewa alama'
			}
		},

		help: {
			'1': {
				content:
					'Karibu kwenye  <b> Ukurasa wa Msaada </b> . Tembelea ukurasa huu unapopata matatizo ili kuelewa jinsi programu tumizi inavyofanya kazi'
			},
			'2': {
				content:
					'Unaweza kutembelea  <b> Ukurasa wa Msaada  </b> kuendesha tena mwongozo huu wakati wowote...'
			},
			'3': {
				content: 'Bofya kwenye moja kati ya vipengele vilivyo kwenye orodha kupata taarifa zaidi…'
			},
			'4': {
				content: 'Njia nyingine, unaweza kuwasiliana na Chalkboard Education kwa baruapepe'
			},
			'5': {
				content: 'Au zungumza nasi kwa kutupigia simu kwenye namba yetu ya moja kwa moja…'
			}
		},

		quiz: {
			'1':
				'Karibu  <b> Ukurasa wa Zoezi  </b>. Utaiona skrini ileile wakati wowote unapotaka kufanya Zoezi',
			'2': 'Hapa kuna maelezo ya Zoezi. Bofya  <b> kitufe cha Anza  </b> ili kuanza...'
		},
		quizQuestion: {
			'1': 'Hii inaonyesha swali unalolifanya, na umebakiza maswali mangapi…',
			'2':
				'Shiriki na jibu swali. Bofya  <b> kitufe Kinachofuata  </b>  chini ya skrini ili kuendelea kwenye swali linalofuata',
			'3':
				'Majibu yako yatahifadhiwa kiotomati kwenye kifaa chako mara utakapoyaingiza. Jisikie huru kuyarudia baadaye, mpaka utakapowasilisha zoezi pale inapowezekana!'
		}
	},
	routes: {
		login: 'Ingia',
		welcome: 'Karibu',
		modules: `${moduleName}`,
		courses: `${courseName}`,
		help: 'Msaada',
		about: 'Kuhusu',
		bookmarks: 'Alama',
		quizReview: 'Mapitio ya Zoezi',
		account: 'Akaunti',
		folders: `Chini ya maelezo ya ${courseName} ni orodha ya  <b> ${folderName}  </b>`,
		sessions: `${sessionName}`,
		quiz: 'Zoezi',
		question: 'Swali'
	},
	bookmarks: {
		none: 'Inaonekana kama hujahifadhi alama...',
		added: 'Alama iliyoongezwa',
		removed: 'Alama iliyoondolewa'
	},
	courses: {
		none: `${courseName} zilizopakuliwa yataonekana hapa…`,
		noPurchases: `Bado hujafanya manunuzi yoyote`,
		purchaseMore: `Bofya hapa kuongeza ${courseName}`,
		purchaseCourses: `Nunua ${courseName}`
	},
	quizReview: {
		questionsTitle: {
			survey: 'Maswali & Majibu',
			graded: 'Vunjavunja'
		},
		none: `Hujakamilisha <b> Iliyowekwa kwenye daraja</b>mazoezi au  <b> Mapitio  </b> yoyote`,
		correct: 'Sahihi',
		wrong: 'Si sahihi',
		notMarked: 'Bado haijatiwa alama',
		resultsIsFetching: 'Inaleta maswali yaliyopita kwa ajili ya mapitio...',
		errorFetchingResults:
			'Samahani, kumetokea hitilafu wakati wa kupata matokeo ya maswali. Tafadhali angalia masasisho ili ujaribu tena.'
	},
	folder: {
		quizLabel: `Lazima ukamilishe Zoezi kabla ya kuangalia Taarifa za jina hili la ${folderName}:`,
		surveyLabel: `Lazima ukamilishe Mapitio kabla ya kuangalia Taarifa za jina hili la ${folderName}:`
	},
	modules: {
		none: `Hakuna ${moduleName} yaliyopakuliwa…`
	},
	register: {
		signUp: 'Ingia',
		button: 'SAJILI',
		registering: 'KUJISAJILI',
		loginHint: 'Tayari una akaunti? Ingia',
		successful: 'Usajili umekamilika',
		failed: 'Usajili umeshindikana. Tafadhali jaribu tena.',
		label: {
			firstName: 'Jina la Kwanza',
			lastName: 'Jina la Mwisho',
			phoneNumber: 'Namba ya Simu',
			language: 'Lugha'
		},
		hint: {
			firstName: 'Jina la Kwanza linahitajika',
			lastName: 'Jina la Mwisho linahitajika',
			phoneNumberLength: 'Namba ya simu inapaswa kuwa na urefu wa tarakimu zaidi ya 9',
			phoneNumberFormat: "Phone number should start with a '+'",
			verifyHuman: 'Tafadhali thibitisha mtumiaji ni binadamu ili kuendelea',
			language: 'Lugha inahitajika'
		}
	},
	searchModal: {
		cancel: 'Ghairi',
		search: 'Tafuta',
		searchIn: 'Tafuta'
	},
	quizType: {
		survey: 'Utafiti',
		graded: 'Iliyopewa daraja',
		selfAssessment: 'Tathmini binafsi'
	}
});
