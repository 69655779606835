export default ({
	course: courseName,
	session: sessionName,
	folder: folderName,
	module: moduleName
}: {
	course: any;
	session: any;
	folder: any;
	module: any;
}) => ({
	course: {
		noContentAvailable: 'No content available',
		view: 'View',
		sessionsCompleted: "You've successfully completed all sessions in the course <b>%{courseName}</b>",
		congratulations: 'Congratulations',
		great: 'Great'
	},
	session: {
		nextButton: 'Next',
		previous: 'Previous',
		quizButton: 'Proceed to Quiz',
		surveyButton: 'Proceed to Survey',
		end: 'Complete'
	},
	question: {
		next: 'Next',
		previous: 'Previous',
		saveAndNext: 'Save & Next',
		answer: 'Answer',
		checkAnswer: 'Check Answer',
		numericalRangeFeedback:
			'The correct answer is a number between <b>%{lowerNumber}</b> and <b>%{higherNumber}</b> inclusive',
		answerAll: 'Kindly answer all <b>required</b> questions before proceeding.',
		maxFiles: 'You can only upload a maximum of %{number} videos',
		uploadAll: 'Kindly upload all <b>required</b> files before proceeding.',
		confirmRemove: 'Are you sure you want to remove the file',
		confirm: 'Confirm',
		backToStart: 'Back to Start',
		attempt: 'Kindly attempt the question before proceeding.',
		question: 'Question',
		of: 'of',
		end: 'Finish',
		hint: 'Click to toggle hint',
		helperText: {
			essay: 'Enter answer below',
			multipleChoice: 'Select one answer',
			compoundMultipleChoice: 'Select all appropriate answers',
			numericalRange: 'Enter a number within the range of appropriate answers',
			shortExact: 'Enter answer below',
			sequence: 'Drag and drop items to arrange them in the appropriate order',
			ratingScale: 'Drag the slider to select and answer'
		}
	},
	feedback: {
		good: 'Good Job!',
		bad: 'Incorrect'
	},
	tokenError: {
		'401': 'Your link has a problem - please contact Chalkboard Education for support.',
		'402':
			'Your token is in use on another device. Multi-device logins are prohibited by your Institution. Kindly contact Chalkboard Education if you believe this is an error.'
	},
	discussion: {
		title: 'Discussion',
		messagePlaceholder: 'Enter your message'
	},
	update: {
		label: 'Download',
		checkForUpdates: 'Check for updates',
		needUpdate: 'Content updates are available...',
		updating: 'Fetching content...',
		fetchingQuizResults: 'Fetching quiz results...',
		checking: 'Checking for content...',
		downloadSize: '{{size}} {{type}}',
		downloadProgress: '{{completed}}/{{total}} session content and media downloaded',
		toDownload: ' to download',
		KB: 'KB',
		MB: 'MB',
		upToDate: 'All available content already downloaded!',
		errorWhileCheckingUpdates: 'There was a network problem whilst checking for updates',
		errorWhileUpdating: 'A network problem occurred whilst downloading content',
		stayOnline: 'Kindly stay online and keep the page open. This may take several minutes.',
		connectAndRetry: 'Please make sure you are connected to the internet and retry',
		connectAndRetryInMinutes:
			'Please make sure you are connected to the internet and try again in a few minutes',
		downloadInProgress: 'Download in progress',
		contentWillAppear: 'You will see content as soon as it is complete',
		offline: 'You are offline.',
		success: 'Content downloaded successfully!',
		warning: 'Content partially downloaded',
		retry: 'Retry',
		retryInFewMinutes: 'Please try again in a few minutes',
		edit: 'Edit',
		downloadingContent: 'Downloading Content',
		missing: 'Download missing content',
		dismiss: 'Dismiss',
		contentLoading:
			'Content download in progress. You can view the status by tapping the circle in the bottom right of your screen'
	},
	send: {
		label: {
			quiz: 'Submit your progress with:',
			survey:
				"Great job on completing the survey! To ensure accuracy, kindly review your responses. Once you are certain about your responses, please proceed by clicking the 'Submit' button.\""
		},
		sessionListButton: `${sessionName}s list`,
		nextButton: 'Next',
		medium: {
			internet: 'Internet',
			sms: 'SMS'
		},
		validation: {
			success: 'Thank you. Your progress is saved.',
			fail: `Error while validating ${sessionName}`
		},
		sms: {
			title: 'Submit your progress by SMS',
			label:
				'You will send a code by SMS and receive a progress code back. Do not modify either codes. Thank you.',
			button: 'Send by SMS',
			notworking: {
				label: 'Send SMS manually...',
				button: 'Not working ?',
				toPhone: 'Phone Number',
				unblockCode: 'Code to send'
			},
			progressCode: {
				button: 'Enter Progress Code',
				placeholder: 'Progress Code',
				title: 'Enter the code you received back from the SMS below:',
				cancel: 'Cancel',
				submit: 'Submit'
			}
		}
	},
	login: {
		checking: 'Checking ...',
		start: 'Start',
		title: 'Login',
		message:
			'You can log in with the SMS link provided. Alternatively, you may enter your login token below.',
		hint:
			'You may use the login link sent to you via SMS to log back in.<br />Alternatively, your 6-character token can be found appended to the login URL sent to you via SMS. For example: https://chalkedu.co/#/login/<b>token</b>',
		tokenPlaceholder: 'Token'
	},
	account: {
		progress: {
			title: `${sessionName} Progress`,
			subtitle: `%{read}/%{remaining} ${sessionName}s read`
		},
		language: 'Language',
		settings: {
			label: 'Settings'
		},
		about: 'About',
		consent: 'Consent',
		support: {
			title: 'Support Lines',
			subtitle: 'You can contact us through these methods for further help:'
		}
	},
	error: {
		label: 'Whoops... Looks like something went wrong.',
		report: 'Report Error',
		back: 'Back to Home',
		dismiss: 'Dismiss',
		authenticationError: 'Authentication Error',
		spoolError: {
			title: 'Incomplete Download',
			description:
				"Some files failed to download. Not to worry, we'll attempt to redownload them on the next update."
		}
	},
	notFound: {
		title: '404 page not found',
		description: 'We are sorry but the page you are looking for does not exist.',
		back: 'Go Back'
	},
	logout: {
		title: 'Confirm Logout',
		message: 'Are you sure you want to logout?',
		button: 'Logout',
		cancelButton: 'Cancel'
	},
	selfTestQuiz: {
		okay: 'Okay',
		checkAnswer: 'Check Answer',
		answers: 'Answers',
		essayResponse: 'Thank you for your response!'
	},
	quiz: {
		label: 'You must complete the following Quiz before you proceed!',
		button: {
			begin: 'Begin',
			skip: 'Skip'
		},
		upload: 'Select files for upload',
		uploadedFiles: 'Uploaded files',
		tapToUpload: 'Tap the icon to select files to upload',
		commence: "You are about to take this session's quiz",
		submit: {
			submitting: 'Submitting',
			stayOnline:
				'Are you sure you want to submit your answers?\nPlease ensure you are online and keep the screen open',
			inProgress: 'Submission in progress. This may take up to a minute',
			success: 'Quiz submitted successfully!',
			error: 'Error submitting quiz! Kindly try again later',
			offline: 'Cannot submit responses while offline'
		},
		continue: 'Continue',
		submitButton: 'Submit Quiz'
	},
	survey: {
		submitButton: 'Submit Survey',
		submittedButton: 'Survey Submitted',
		commence: "You are about to take this session's survey",
		submit: {
			success: 'Survey submitted successfully!'
		}
	},
	badge: {
		quiz: 'Quiz',
		survey: 'Survey',
		required: 'Required'
	},
	menu: {
		modules: `${moduleName}s`,
		courses: `${courseName}s`,
		account: 'Account',
		bookmarks: 'Bookmarks',
		quizReview: 'Quiz Review',
		help: 'Help',
		about: 'About'
	},
	consent: {
		title: 'I consent to Chalkboard Education SAS collecting and conserving:',
		data: 'My progression and results data from this application',
		analytics: 'My behavioral data and other personal information with regards to the use of this app'
	},
	custom: {
		moreInfo: 'More Information',
		gotIt: 'Got it',
		searchPlaceholder: 'Start typing to search',
		noSearchResults: 'No results found'
	},
	help: {
		title: 'How to navigate the app:',
		menu: {
			title: 'Discover the Menu',
			subtitle: `Click the icon to open the menu. The menu contains a link to your ${courseName}s and your Account.`
		},
		courses: {
			title: `${courseName}s`,
			subtitle: `On the ${courseName}s page, click on 'View' to open a ${courseName}.`
		},
		folders: {
			title: `${folderName}s`,
			subtitle: `Each ${courseName} is divided into ${folderName}s.`,
			description: `Each ${folderName} is further divided into ${sessionName}s. ${sessionName}s contain content the user can study.`
		},
		sessions: {
			title: `${sessionName}s`,
			subtitle: `${sessionName}s contain content the user can study.`
		},
		navigatingSessions: {
			title: `Navigating ${sessionName}s`,
			back: {
				subtitle: `Once in a ${sessionName}, you can either navigate back to the parent list with the back arrow...`
			},
			next: {
				subtitle: `Or to the next ${sessionName} with the button at the bottom of the page:`
			}
		},
		bookmarks: {
			title: 'Bookmarks',
			subtitle:
				'Use the bookmark icon to save pages for quick access from the Bookmarks section within the side menu'
		},
		quizzes: {
			title: 'Quizzes/Tests',
			subtitle: `You may be presented with a Quiz at the beginning of a ${courseName}, or ${folderName}. Or at the end of a ${sessionName}.`,
			description: `NB: ${sessionName}s with a Quiz are marked with the icon:`
		},
		addToHomescreen: {
			title: 'Add To Homescreen',
			subtitle:
				"You can install this application on your homescreen for quick and easy access when you're on the go."
		},
		account: {
			title: 'Account',
			subtitle: 'On the Account page, you can:',
			updates: {
				description: 'Check for updates to content.'
			},
			language: {
				description: 'Change your language settings.'
			},
			logout: {
				description: 'Log out of your account.'
			},
			contact: {
				description: 'Contact us with any issues you have with using the app.'
			}
		},
		tutorial: 'Re-run the interactive tutorial:'
	},
	onboarding: {
		'0': {
			title: 'On-demand lessons',
			subtitle: 'All your learning material in one place... Including quizzes to test your knowledge.'
		},
		'1': {
			title: 'Offline-capable!',
			subtitle: 'Once your content is downloaded, it is available offline so you can access it anytime.'
		},
		'2': {
			title: 'Learn anytime, anywhere!',
			subtitle: 'Access your content on any device. Just use the SMS link provided.'
		},
		'3': {
			title: 'Join the discussion!',
			subtitle: `Participate in peer-to-peer learning on discussion-enabled ${sessionName}s.`
		},
		'4': {
			title: 'Add to your homescreen!',
			subtitle:
				"Install this application on your homescreen for quick and easy access when you're on the go.",
			instructions: {
				menu:
					'Just tap the <strong>menu</strong> button in your browser <br />and select <strong>Add to Home Screen</strong>',
				share:
					'Just tap the <strong>share</strong> button in your browser<br />and select <strong>Add to Home Screen</strong>',
				clickButton: '...or by clicking the button below'
			}
		},
		addToHomescreen: 'Add To Homescreen',
		getStarted: 'Get Started',
		next: 'Next',
		skip: 'Skip'
	},
	'guided-tour': {
		title: 'Interactive Tutorial',
		button: {
			finish: 'End',
			next: 'Next',
			previous: 'Previous',
			're-run': 'Re-run'
		},
		modules: {
			'1': {
				title: `${moduleName}s Page`,
				content: `Welcome to the <b>${moduleName}s</b> page. <b>${moduleName}s</b> are groups of <b>${courseName}s</b> that you have been assigned to`
			},
			'2': {
				content: `Clicking on a <b>${moduleName}</b> takes you to a list of all the <b>${courseName}s</b> under the <b>${moduleName}</b>`
			}
		},
		'module-courses': {
			'1': {
				content: `You're on the <b>${courseName}s</b> page of the <b>${moduleName}</b> you just selected. The ${courseName}s under the <b>${moduleName}</b> are listed here`
			}
		},
		courses: {
			'1': {
				title: `${courseName}s Page`,
				content: `You're on the <b>${courseName}s</b> page. All of your <b>${courseName}s</b> are listed here`
			},
			'2': {
				content: 'Whenever you see this, it means there is new content available for download'
			},
			'3': {
				content: 'Clicking on <b>Download</b> saves the new content onto your device'
			},
			'4': {
				content: `Clicking on the image reveals a short desciption of the <b>${courseName}</b>`
			},
			'5': {
				content: `Clicking on a <b>${courseName}</b> takes you to the information page of that <b>${courseName}</b>`
			},
			'6': {
				content: 'Click on <b>Check For Updates</b> to check for new content'
			}
		},
		'course-info': {
			'1': {
				content: `This is the <b>${courseName}s Page</b>. Here you will find more information on a ${courseName}, as well as your <b>${sessionName}s</b> grouped into <b>${folderName}s</b>`
			},
			'2': {
				content: `You can find a brief description about the <b>${courseName}</b> here`
			},
			'3': {
				content: `Below the ${courseName} description is a list of <b>${folderName}s</b>`
			},
			'4': {
				content: `Clicking on a <b>${folderName}</b> reveals the list of <b>${sessionName}s</b> under that ${folderName}`
			},
			'5': {
				content: `Here are the list of <b>${sessionName}s</b>`
			},
			'6': {
				content: `To start reading, click on a <b>${sessionName}</b>`
			}
		},
		'session-details': {
			'1': {
				content: `Welcome to your very first <b>${sessionName}</b>. Here's where you'll be spending most of your time`
			},
			'2': {
				content: `To make it easier to return to a <b>${sessionName}</b>, click on the <b>Bookmark</b> icon to create a quick link back to this <b>${sessionName}</b>. <b>Bookmarks</b> you create can be found in the <b>Bookmarks Page</b>`
			},
			'3': {
				content: 'Clicking the up arrow takes you to the previous page.'
			},
			'4': {
				content: `Click the <b>Discussion Icon</b> to view the <b>Discussion Page</b> of the <b>${sessionName}</b> you're currently viewing`
			},
			'5': {
				content: `To go to the next <b>${sessionName}</b> in the <b>${folderName}</b>, click the <b>Next Button</b>`
			},
			'6': {
				content: `To move to the previous <b>${sessionName}</b>, click the <b>Previous Button</b>`
			},
			'7': {
				content:
					'This is the <b>Menu Button</b>. Click on it on any page, reveals the <b>Side Menu</b>'
			}
		},
		bookmarks: {
			'1': {
				content: 'All pages you have bookmarked appear here!'
			},
			'2': {
				content: 'Clicking on a <b>Bookmark</b> quickly takes you to the bookmarked page'
			}
		},

		help: {
			'1': {
				content:
					'Welcome to the <b>Help Page</b>. Visit this page whenever you have trouble understanding how the app works'
			},
			'2': {
				content: 'You may visit the <b>Help Page</b> to re-run this guide at any time...'
			},
			'3': {
				content: 'Click on any of the items in the list to get more information...'
			},
			'4': {
				content: 'Alternatively, you can contact Chalkboard Education via email'
			},
			'5': {
				content: 'Or speak to us by calling us on our hotline...'
			}
		},

		quiz: {
			'1':
				'Welcome to a <b>Quiz Page</b>. You will see a similar screen whenever you are about to attempt a Quiz',
			'2': 'Here are the details of the Quiz. Click the <b>Begin</b> button to begin...'
		},
		quizQuestion: {
			'1': 'This shows which question you are on, and how many questions you have left...',
			'2':
				'Interact with and answer the question. Click the <b>Next</b> button at the bottom of the screen to proceed to the next question',
			'3':
				'Your answers are automatically saved on your device once you input them. Feel free to return to them later on, until you submit the quiz if possible!'
		}
	},
	routes: {
		login: 'Login',
		welcome: 'Welcome',
		modules: `${moduleName}s`,
		courses: `${courseName}s`,
		help: 'Help',
		about: 'About',
		bookmarks: 'Bookmarks',
		quizReview: 'Quiz Review',
		account: 'Account',
		folders: `${folderName}s`,
		sessions: `${sessionName}s`,
		quiz: 'Quiz',
		question: 'Question'
	},
	bookmarks: {
		none: 'Looks like you have no saved bookmarks...',
		added: 'Bookmark added',
		removed: 'Bookmark removed'
	},
	courses: {
		none: `Downloaded ${courseName}s will appear here...`,
		noPurchases: `You haven't made any purchases yet`,
		purchaseMore: `Click here to add ${courseName}s`,
		purchaseCourses: `Purchase ${courseName}s`
	},
	quizReview: {
		questionsTitle: {
			survey: 'Questions & Responses',
			graded: 'Breakdown'
		},
		none: `You have not completed any <b>Graded</b> quizzes or <b>Surveys</b>`,
		correct: 'Correct',
		wrong: 'Wrong',
		notMarked: 'Not marked yet',
		resultsIsFetching: 'Fetching previously taken quizzes for review...',
		errorFetchingResults:
			'Sorry there was an error fetching quiz results. Please check for updates to try again.'
	},
	folder: {
		quizLabel: `You must complete a Quiz before viewing this ${folderName}'s contents:`,
		surveyLabel: `You must complete a Survey before viewing this ${folderName}'s contents:`
	},
	modules: {
		none: `No ${moduleName}s downloaded...`
	},
	register: {
		signUp: 'Sign Up',
		button: 'REGISTER',
		registering: 'REGISTERING...',
		loginHint: 'Already have an account? Sign in',
		successful: 'Registration successful',
		failed: 'Registration failed. Please try again',
		label: {
			firstName: 'First Name',
			lastName: 'Last Name',
			phoneNumber: 'Phone Number',
			language: 'Language'
		},
		hint: {
			firstName: 'First Name is required',
			lastName: 'Last Name is required',
			phoneNumberLength: 'Phone number should be more than 9 digits long',
			phoneNumberFormat: "Phone number should start with a '+'",
			verifyHuman: 'Please verify recaptcha to proceed',
			language: 'Language is required'
		}
	},
	searchModal: {
		cancel: 'Cancel',
		search: 'Search',
		searchIn: 'Search In'
	},
	quizType: {
		survey: 'Survey',
		graded: 'Graded',
		selfAssessment: 'Self Assessment'
	}
});
