export default () => ({
	course: {
		noContentAvailable: 'Nenhum conteúdo disponível',
		view: 'Visualizar',
		sessionsCompleted: 'Você concluiu com sucesso todas as sessões do curso %{courseName}',
		congratulations: 'Parabéns',
		great: 'Ótimo'
	},
	session: {
		nextButton: 'Próximo',
		previous: 'Anterior',
		quizButton: 'Proceda ao Quiz',
		surveyButton: 'Proceda ao Inquérito',
		end: 'Completo'
	},
	question: {
		next: 'Próximo',
		previous: 'Anterior',
		saveAndNext: 'Guardar & Próximo',
		answer: 'Resposta',
		checkAnswer: 'Consultar Resposta',
		numericalRangeFeedback:
			'A resposta correta é um número entre <b>%{lowerNumber}</b> e <b>%{higherNumber}</b>, inclusive',
		answerAll: 'Por favor, responda a todas as perguntas <b>necessárias</b> antes de prosseguir.',
		maxFiles: 'Você só pode fazer upload de um máximo de %{number} vídeos',
		uploadAll: 'Por favor, faça o upload de todos os arquivos <b>obrigatórios</b> antes de prosseguir.',
		confirmRemove: 'Tem certeza de que deseja remover o arquivo',
		confirm: 'Confirmar',
		backToStart: 'Voltar ao Início',
		attempt: 'Por favor, responda à pergunta antes de prosseguir.',
		question: 'Pergunta',
		of: 'de',
		end: 'Acabar',
		hint: 'Clique para ativar a dica',
		helperText: {
			essay: 'Insira a resposta abaixo',
			multipleChoice: 'Selecione uma resposta',
			compoundMultipleChoice: 'Selecione todas as respostas apropriadas',
			numericalRange: 'Introduza um número dentro do intervalo de respostas apropriadas',
			shortExact: 'Insira a resposta abaixo',
			sequence: 'Arraste e largue itens para organizá-los na ordem apropriada',
			ratingScale: 'Arraste o slider para selecionar e responder'
		}
	},
	feedback: {
		good: 'Bom trabalho!',
		bad: 'Incorreto'
	},
	tokenError: {
		'401': 'O seu link tem um problema - contacte a Chalkboard Education para obter apoio.',
		'402':
			'O seu token está a ser utilizado noutro dispositivo. Os logins multi-dispositivos são proibidos pela sua Instituição. Por favor, contacte a Chalkboard Education se acreditar que isto é um erro.'
	},
	discussion: {
		title: 'Discussão',
		messagePlaceholder: 'Digite sua mensagem'
	},
	update: {
		label: 'Baixar',
		checkForUpdates: 'Verificar atualizações',
		needUpdate: 'As atualizações de conteúdo estão disponíveis...',
		updating: 'A obter conteúdo...',
		checking: 'A verificar conteúdo...',
		fetchingQuizResults: 'Buscando resultados do quiz...',
		downloadSize: '{{size}} {{type}}',
		downloadProgress: '{{completed}}/{{total}} do conteúdo e mídia da sessão baixados',
		toDownload: ' para baixar',
		KB: 'KB',
		MB: 'MB',
		upToDate: 'Todo o conteúdo disponível já foi descarregado!',
		errorWhileCheckingUpdates: 'Houve um problema de rede enquanto verificava as atualizações',
		errorWhileUpdating: 'Um problema de rede ocorreu ao descarregar conteúdo',
		stayOnline: 'Por favor, permaneça online e mantenha a página aberta. Isto pode levar alguns minutos.',
		offline: 'Está offline.',
		success: 'Conteúdo descarregado com sucesso!',
		warning: 'Conteúdo parcialmente descarregado',
		connectAndRetry: 'Por favor, certifique-se de que está conectado à Internet e tente novamente',
		connectAndRetryInMinutes:
			'Por favor, certifique-se de estar conectado à Internet e tente novamente em alguns minutos',
		downloadInProgress: 'Download em andamento',
		contentWillAppear: 'Você verá o conteúdo assim que estiver completo',
		retry: 'Tentar novamente',
		retryInFewMinutes: 'Por favor, tente novamente em alguns minutos',
		edit: 'Editar',
		downloadingContent: 'Descarregar Conteúdo',
		missing: 'Baixar mídia ausente',
		dismiss: 'Dispensar',
		contentLoading:
			'Download de conteúdo em andamento. Você pode ver o status tocando no círculo no canto inferior direito da sua tela'
	},
	send: {
		label: {
			quiz: 'Submeta o seu progresso com:',
			survey:
				"Ótimo trabalho ao concluir a pesquisa! Para garantir a precisão, por favor, reveja suas respostas. Assim que tiver certeza das suas respostas, prossiga clicando no botão 'Enviar'"
		},
		sessionListButton: `Lista de sessões`,
		nextButton: 'Próximo',
		medium: {
			internet: 'Internet',
			sms: 'SMS'
		},
		validation: {
			success: 'Obrigado. O teu progresso está salvo.',
			fail: `Erro ao validar sessão`
		},
		sms: {
			title: 'Envie o seu progresso por SMS',
			label:
				'Enviará um código por SMS e receberá um código de progresso de volta. Não modifique nenhum dos códigos. Obrigado.',
			button: 'Enviar por SMS',
			notworking: {
				label: 'Envie SMS manualmente...',
				button: 'Não está a funcionar?',
				toPhone: 'Número de Telefone',
				unblockCode: 'Código para enviar'
			},
			progressCode: {
				button: 'Insira o Código de Progresso',
				placeholder: 'Código de Progresso',
				title: 'Introduza o código que recebeu por SMS abaixo:',
				cancel: 'Cancelar',
				submit: 'Enviar'
			}
		}
	},
	login: {
		checking: 'A verificar...',
		start: 'Começar',
		title: 'Entrar',
		message:
			'Pode iniciar sessão com o link SMS fornecido. Em alternativa, pode introduzir o seu token de login abaixo.',
		hint:
			'Pode utilizar o link de login enviado através de SMS para fazer login novamente. <br />Alternativamente, o seu token de 6 caracteres pode ser encontrado anexado ao URL de login enviado por SMS. Por exemplo: https://chalkedu.co/#/login/ <b>token</b>',
		tokenPlaceholder: 'Token'
	},
	account: {
		progress: {
			title: `Progresso`,
			subtitle: `%{read}/%{remaining} Sessões lidas`
		},
		language: 'Idioma',
		settings: {
			label: 'Definições'
		},
		about: 'Sobre',
		consent: 'Consentimento',
		support: {
			title: 'Linhas de Apoio',
			subtitle: 'Pode contactar-nos através destes métodos para mais ajuda:'
		}
	},
	error: {
		label: 'Ups... parece que algo correu mal.',
		report: 'Erro de Relatório',
		back: 'De volta ao Início',
		dismiss: 'Dispensar',
		authenticationError: 'Erro de Autenticação',
		spoolError: {
			title: 'Download Incompleto',
			description:
				'Alguns ficheiros não foram descarregados. Não se preocupe, tentaremos descarregá-los na próxima atualização.'
		}
	},
	'404': {
		title: '404 página não encontrada',
		description: 'Lamentamos, mas a página que procura não existe.',
		back: 'Voltar'
	},
	logout: {
		title: 'Confirmar Logout',
		message: 'Tem certeza que quer fazer o logout?',
		button: 'Sair',
		cancelButton: 'Cancelar'
	},
	selfTestQuiz: {
		okay: 'Ok',
		checkAnswer: 'Consultar Resposta',
		answers: 'Respostas',
		essayResponse: 'Obrigado pela sua resposta!'
	},
	quiz: {
		label: 'Tem de completar o seguinte Quiz antes de prosseguir!',
		button: {
			begin: 'Começar',
			skip: 'Saltar'
		},
		upload: 'Selecionar arquivos para upload',
		uploadedFiles: 'Arquivos enviados',
		tapToUpload: 'Toque no ícone para selecionar arquivos para upload',
		commence: 'Você está prestes a fazer o questionário desta sessão',
		submit: {
			submitting: 'Enviando',
			stayOnline:
				'Tem certeza de que deseja enviar suas respostas?\nPor favor, certifique-se de estar online e mantenha a tela aberta',
			inProgress: 'Processo em andamento. Isso pode levar até um minuto',
			success: 'Quiz submetido com sucesso!',
			offline: 'Não é possível enviar respostas enquanto estiver offline',
			error: 'Erro ma submissão do quiz! Tente novamente mais tarde.'
		},
		continue: 'Continuar',
		submitButton: 'Enviar questionário'
	},
	survey: {
		submitButton: 'Submenter Inquérito',
		submittedButton: 'Inquérito Submetido',
		commence: 'Você está prestes a responder ao questionário desta sessão',
		submit: {
			success: 'Questionário submetido com sucesso!'
		}
	},
	badge: {
		quiz: 'Quiz',
		survey: 'Inquérito',
		required: 'Necessário'
	},
	menu: {
		modules: `Módulos`,
		courses: `Cursos`,
		account: 'Conta',
		bookmarks: 'Marcadores',
		quizReview: 'Análise ao Quiz',
		help: 'Ajuda',
		about: 'Sobre'
	},
	consent: {
		title: 'Autorizo a Chalkboard Education SAS a recolher e conservar:',
		data: 'Os meus dados de progressão e resultados desta aplicação',
		analytics:
			'Os meus dados comportamentais e outras informações pessoais no que diz respeito ao uso desta aplicação'
	},
	custom: {
		moreInfo: 'Mais informações',
		gotIt: 'Percebi',
		searchPlaceholder: 'Comece a escrever para pesquisar',
		noSearchResults: 'Não foram encontrados resultados'
	},
	help: {
		title: 'Como navegar na aplicação:',
		menu: {
			title: 'Descubra o Menu',
			subtitle: `Clique no ícone para abrir o menu. O menu contém um link para os seus Cursos e a sua Conta.`
		},
		courses: {
			title: `Cursos`,
			subtitle: `Na página Cursos, clique em <b>Ver</b> para abrir um Curso.`
		},
		folders: {
			title: `Pastas`,
			subtitle: `Cada curso é dividido em pastas.`,
			description: `Cada pasta é dividida em Sessões. As sessões contêm conteúdo que o utilizador pode estudar.`
		},
		sessions: {
			title: `Sessões`,
			subtitle: `As sessões contêm conteúdo que o utilizador pode estudar.`
		},
		navigatingSessions: {
			title: `Sessões de Navegação`,
			back: {
				subtitle: `Quando se está numa sessão, pode-se navegar de volta à lista principal com a seta para trás...`
			},
			next: {
				subtitle: `Ou para a próxima Sessão com o botão na parte inferior da página:`
			}
		},
		bookmarks: {
			title: 'Marcadores',
			subtitle:
				'Use o ícone do marcador para guardar páginas para um acesso rápido a partir da secção Marcadores dentro do menu lateral'
		},
		quizzes: {
			title: 'Quizzes/Testes',
			subtitle: `Pode ser presenteado com um Quiz no início de um Curso ou Pasta. Ou no final de uma sessão.`,
			description: `NB: As sessões com um Quiz são marcadas com o ícone:`
		},
		addToHomescreen: {
			title: 'Adicionar ao Ecrã Principal',
			subtitle:
				'Pode instalar esta aplicação no seu ecrã principal para um acesso rápido e fácil quando estiver em movimento.'
		},
		account: {
			title: 'Conta',
			subtitle: 'Na página Cursos, clique em <b>Ver</b> para abrir um Curso.',
			updates: {
				description: 'Verifique se há atualizações de conteúdo.'
			},
			language: {
				description: 'Altere as definições de idioma.'
			},
			logout: {
				description: 'Faça login na sua conta.'
			},
			contact: {
				description: 'Contacte-nos com quaisquer problemas que tenha com a utilização da app.'
			}
		},
		tutorial: 'Re-executar o tutorial interativo:'
	},
	onboarding: {
		'0': {
			title: 'Lições a pedido',
			subtitle:
				'Todo o seu material de aprendizagem num só lugar... incluindo testes para testar o seu conhecimento.'
		},
		'1': {
			title: 'Capacidades off-line!',
			subtitle:
				'Uma vez descarregado o seu conteúdo, este fica disponível offline para que possa aceder ao mesmo em qualquer altura.'
		},
		'2': {
			title: 'Aprenda a qualquer hora, em qualquer lugar!',
			subtitle: 'Aceda ao seu conteúdo em qualquer dispositivo. Basta utilizar o link SMS fornecido.'
		},
		'3': {
			title: 'Junte-se à discussão!',
			subtitle: `Participar na aprendizagem par-a-par em sessões de discussão.`
		},
		'4': {
			title: 'Adicione ao seu ecrã principal!',
			subtitle:
				'Instale esta aplicação no seu ecrã principal para um acesso rápido e fácil quando estiver em movimento.',
			instructions: {
				menu:
					'Basta tocar no botão <strong>menu</strong> do seu navegador <br />e selecionar <strong>Adicionar ao Ecrã Principal</strong>',
				share:
					'Basta tocar no botão <strong>de partilha</strong> no seu browser<br />e selecionar <strong>Adicionar ao Ecrã Principal</strong>',
				clickButton: '... ou clicando no botão abaixo'
			}
		},
		addToHomescreen: 'Adicionar ao Ecrã Principal',
		getStarted: 'Começar',
		next: 'Próximo',
		skip: 'Saltar'
	},
	'guided-tour': {
		title: 'Tutorial Interativo',
		button: {
			finish: 'Fim',
			next: 'Próximo',
			previous: 'Anterior',
			're-run': 'Re-executar'
		},
		modules: {
			'1': {
				title: `Página de Módulos`,
				content: `Bem-vindo à página de <b>Módulos</b>. Os <b> módulos</b> são <b>grupos de Cursos</b> que lhe foram atribuídos.`
			},
			'2': {
				content: `Clicar num <b>Módulo</b> leva-o a uma lista de todos os <b>Cursos</b> dentro do <b>Módulo</b>`
			}
		},
		'module-courses': {
			'1': {
				content: `Está na página <b>cursos</b> do <b>Módulo</b> que acabou de selecionar. Os Cursos ao abrigo do <b>Módulo</b> estão listados aqui`
			}
		},
		courses: {
			'1': {
				title: `Página de Cursos`,
				content: `Está na página de <b>Cursos</b>. Todos os seus <b>Cursos estão listados</b> aqui`
			},
			'2': {
				content: 'Sempre que vê isto, significa que há novos conteúdos disponíveis para download'
			},
			'3': {
				content: 'Clicar em <b>Download</b> guarda o novo conteúdo no seu dispositivo'
			},
			'4': {
				content: `Clicar na imagem revela uma breve descrição do <b>Curso</b>`
			},
			'5': {
				content: `Clicar num <b>Curso</b> leva-o à página de informação desse <b>Curso</b>`
			},
			'6': {
				content: 'Clique em <b>Verificar Atualizações</b> para verificar novos conteúdos'
			}
		},
		'course-info': {
			'1': {
				content: `Esta é a <b>Página de Cursos</b>. Aqui encontrará mais informações sobre um Curso, bem como as suas <b>Sessões</b> agrupadas em <b>Pastas</b>`
			},
			'2': {
				content: `Pode encontrar uma breve descrição do <b>Curso</b> aqui`
			},
			'3': {
				content: `Abaixo da descrição do Curso está uma lista de <b>Pastas</b>`
			},
			'4': {
				content: `Clicar numa <b>Pasta</b> revela a lista de <b>Sessões</b> dentro dessa pasta`
			},
			'5': {
				content: `Aqui está a lista de <b>Sessões</b>`
			},
			'6': {
				content: `Para começar a ler, clique numa <b>Sessão</b>`
			}
		},
		'session-details': {
			'1': {
				content: `Bem-vindo à sua primeira <b>Sessão</b>. Aqui é onde vai passar a maior parte do seu tempo.`
			},
			'2': {
				content: `Para facilitar o regresso a uma <b>Sessão</b>, clique no ícone <b>Marcador</b> para criar uma ligação rápida a esta <b>Sessão</b>. Os <b>Marcadores</b> que criar podem ser encontrados na <b>Página dos Marcadores</b>`
			},
			'3': {
				content: 'Clicar na seta leva-o à página anterior.'
			},
			'4': {
				content: `Clique no <b>Ícone de Discussão</b> para ver a <b>Página de Discussão</b> da <b>Sessão</b> que está a visualizar`
			},
			'5': {
				content: `Para ir à próxima <b>Sessão</b> na <b>Pasta</b>, clique no <b>Botão Seguinte</b>`
			},
			'6': {
				content: `Para passar para a <b>Sessão</b> anterior, clique no <b>Botão Anterior</b>`
			},
			'7': {
				content:
					'Este é o <b>Botão de Menu</b>. Clicar nele em qualquer página revela o <b>Menu Lateral</b>'
			}
		},
		bookmarks: {
			'1': {
				content: 'Todas as páginas que marcou aparecem aqui!'
			},
			'2': {
				content: 'Clicar num <b>Marcador</b> rapidamente leva-o à página marcada'
			}
		},

		help: {
			'1': {
				content:
					'Bem-vindo à <b>Página de Ajuda</b>. Visite esta página sempre que tiver dificuldade em entender como a aplicação funciona'
			},
			'2': {
				content:
					'Pode visitar a <b>Página de Ajuda</b> para reiniciar este guia a qualquer momento...'
			},
			'3': {
				content: 'Clique em qualquer um dos artigos da lista para obter mais informações...'
			},
			'4': {
				content: 'Em alternativa, pode contactar a Chalkboard Education por e-mail'
			},
			'5': {
				content: 'Ou fale connosco ligando-nos para a nossa linha direta...'
			}
		},

		quiz: {
			'1':
				'Bem-vindo a uma <b>Página de Quiz</b>. Verá um ecrã semelhante sempre que estiver prestes a iniciar um Quiz',
			'2': 'Aqui estão os detalhes do Quiz. Clique no botão <b>Iniciar</b> para começar...'
		},
		quizQuestion: {
			'1': 'Isto mostra a pergunta que está a fazer, e quantas perguntas restam...',
			'2':
				'Interaja e responda à pergunta. Clique no botão <b>Seguinte</b> na parte inferior do ecrã para passar à próxima pergunta',
			'3':
				'As suas respostas são automaticamente guardadas no seu dispositivo assim que as introduzir. Sinta-se livre para voltar mais tarde, até submeter o quiz, se possível!'
		}
	},
	routes: {
		login: 'Entrar',
		welcome: 'Bem-vindo',
		modules: `Módulos`,
		courses: `Cursos`,
		help: 'Ajuda',
		about: 'Sobre',
		bookmarks: 'Marcadores',
		quizReview: 'Análise ao Quiz',
		account: 'Conta',
		folders: `Pastas`,
		sessions: `Sessões`,
		quiz: 'Quiz',
		question: 'Pergunta'
	},
	bookmarks: {
		none: 'Parece que não tem nenhum marcador guardado...',
		added: 'Marcador adicionado',
		removed: 'Marcador removido'
	},
	courses: {
		none: `Os Cursos descarregados aparecerão aqui...`,
		noPurchases: `Ainda não fez nenhuma compra.`,
		purchaseMore: `Clique aqui para adicionar Cursos`,
		purchaseCourses: `Comprar Cursos`
	},
	quizReview: {
		questionsTitle: {
			survey: 'Perguntas e Respostas',
			graded: 'Resumo'
		},
		none: `Não completou nenhum Questionário ou <b>Inquérito<b> <b>Classificado<b>`,
		correct: 'Resposta correta',
		wrong: 'Resposta errada',
		notMarked: 'Ainda não marcado',
		resultsIsFetching: 'Buscando os questionários anteriores para revisão...',
		errorFetchingResults:
			'Desculpe, houve um erro ao buscar os resultados do quiz. Por favor, verifique as atualizações e tente novamente.'
	},
	folder: {
		quizLabel: `Tem de completar um Quiz antes de ver o conteúdo desta pasta:`,
		surveyLabel: `Tem de completar um Inquérito antes de ver o conteúdo desta pasta:`
	},
	modules: {
		none: `Nenhum Módulo descarregado...`
	},
	register: {
		signUp: 'Inscrever-se',
		button: 'REGISTAR',
		registering: 'A REGISTAR...',
		loginHint: 'Já tem uma conta? Iniciar sessão',
		successful: 'Registo bem-sucedido',
		failed: 'O registo falhou. Por favor, tente novamente.',
		label: {
			firstName: 'Primeiro Nome',
			lastName: 'Último Nome',
			phoneNumber: 'Número de Telefone',
			language: 'Idioma'
		},
		hint: {
			firstName: 'O Primeiro Nome é obrigatório',
			lastName: 'O Apelido é obrigatório',
			phoneNumberLength: 'O número de telefone deve ter mais de 9 dígitos',
			phoneNumberFormat: "O número de telefone deve começar com um '+'",
			verifyHuman: 'Por favor, verifique o recaptcha para prosseguir',
			language: 'O Idioma é necessário'
		}
	},
	searchModal: {
		cancel: 'Cancelar',
		search: 'Procurar',
		searchIn: 'Procurar em'
	},
	quizType: {
		survey: 'Enquete',
		graded: 'Avaliado',
		selfAssessment: 'Auto-avaliação'
	}
});
